<template>
  <div :class="['chart-branch-3d2', { 'no-rotate': !rotate }]">
    <div
      ref="refChart"
     :class="this.channelWidth >1000? 'refChart' : this.channelWidth >650?'refChart1':'refChart2'"
    ></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-gl'
export default {
  props: {
    x: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 300
    },
    a: {
      type: Number,
      default: 0.1
    },
    w: {
      type: Number,
      default: 0.1
    },
    mai: {
      type: String,
      default: null
    },
    rotate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chart: null,
      channelWidth:null
    }
  },
  computed: {
    getWidth() {
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.resize()
        }
      })
      return this.width * 1.25
    },
    getHeight() {
      return this.width * 0.7
    }
  },
  created(){
    const screenWidth = window.innerWidth;
    // console.log("当前屏幕宽度2:", screenWidth);
    this.channelWidth = screenWidth 
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.x) return
      // console.log('注入');
      
      this.chart = echarts.init(this.$refs.refChart)
      const option = {
        tooltip: {
          trigger: 'none',
          axisPointer: {
            type: 'cross'
          }
        },
        xAxis: {
          type: 'value',
          min: 0,
          max: 100,
          interval: 10
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 300,
          interval: 100
        },
        series: [
          {
            data: this.x,
            type: 'line',
            smooth: true
          }
        ]
      }
        // console.log(this.x,'this.x');
          
      this.chart.setOption(option)

    })
  }
}
</script>

<style lang="scss" scoped>
.chart-branch-3d2 {
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: -4rem;
  margin-left: -1rem;
}

.chart-branch-3d2 > div {
 margin-top: 10px;
}

.chart-branch-3d2.no-rotate::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}

.refChart{
  width: 1000px;
  height: 592px;
  margin-left: 5px;
}
.refChart1{
  width: 592px;
  height: 280px;
  margin-left: 5px;
}

.refChart2{
  width: 370px;
  height: 180px;
  margin-left: 5px;
}
@media screen and (max-width: 390px) {
  .refChart{
  width: 370px;
  height: 180px;
}
}
</style>
