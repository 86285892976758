import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'

// 对公账户列表
export function findPage(data) {
  return axios({
    url: '/mgmt/agent/corporate/account/getPage',
    method: 'get',
    params: data
  })
}

// 财务审核对公账户
export function examineAudit(data) {
  return axios({
    url: '/mgmt/agent/corporate/account/examine',
    method: 'post',
    data
  })
}

// 新增账号
export function assistantAdd(data) {
  return axios({
    url: '/mgmt/agent/corporate/account/add',
    method: 'post',
    data
  })
}
