import { dataAndColor, getColor } from './tyDataAndColor.js';


const config = {
  aMin: 5,
  aMax: 80,
  wMin: 0.5,
  wMax: 20,
  healthColor: ['#0000FA', '#02555A', '#75BA01'],
  unhealthColor: ['#70AD47', '#FFC000', '#ED7D31']
};

/** @internal */
const toFixed2 = (num, fractionDigits = 2) => +num.toFixed(fractionDigits);

/** @internal */
const gaussian = (α, σ, c, μ, x) => {
  return α * Math.exp(-(Math.pow(x - μ, 2) / (2 * Math.pow(σ, 2)))) + c;
};


/**
 * 高斯计算z值
 * @param {number} x - 值
 * @param {number} w - 脉宽
 * @param {number} a - 振幅
 * @param {number} multiple - 倍数
 * @returns {number} - 计算结果
 */
const gause = (x, w, a, multiple) => {
  w = w <= 25 ? w : 25;
  w = w * multiple;
  const sigma = w / 8;
  return toFixed2(a * Math.exp(-(Math.pow(x, 2) / (2 * Math.pow(sigma, 2)))));
};

/**
 * 水平面的高度
 * @param {string[]} totalMai - 总脉
 * @param {number} zMax - z最大值
 * @returns {number} - 计算结果
 */
const getPosition = (totalMai, zMax) => {
  if (totalMai.includes('浮')) return 0.15 * zMax;
  if (totalMai.includes('沉')) return 0.75 * zMax;
  return 0.5 * zMax;
};


/**
 * 水平面
 * @param {number} xLen - x轴数据长度
 * @param {number} yLen - y轴数据长度
 * @param {number} level - z最大值
 * @returns {number[][]} - 生成的表面数据
 */
const getSurfaceData = (xLen, yLen, level) => {
  // console.log(xLen, yLen, level, 'xLen, yLen, level');

  const surfaceData = [];
  for (let y = 0; y <= xLen; y += xLen) {
    for (let x = 0; x <= yLen; x += 0.2) {
      const z = level + Math.sin(x * 0.3 - 3);
      surfaceData.push([toFixed2(x), y, toFixed2(z)]);
    }
  }

  return surfaceData;
};


export class TyThree {
  constructor(options = config) {
    this.config = {
      ...config,
      ...options
    };
  }

  /**
 * 振幅范围
 * @param {number} a 振幅
 * @internal
 */
  aRange(a) {
    // console.log(a, 1234)
    const { aMin, aMax } = this.config;
    // console.log(a, 11);
    return a < aMin ? (a < 1.94 ? 0.1 : aMin) : a > aMax ? aMax : a;
  }

  /**
   * 脉宽范围
   * @param {number} w 脉宽
   * @internal
   */
  wRange(w) {
    const { wMin, wMax } = this.config;
    return w < wMin ? wMin : w > wMax ? wMax : w;
  }

  /**
   * 获取Z值
   * @param x
   * @param y
   * @param L 关、迟位置
   * @param A 振幅
   * @param W 脉宽
   * @internal
   */
  getZ(x, y, L, A, W) {
    const [l1, l2] = L;
    const [a1, a2, a3] = A;
    const [w1, w2, w3] = W;
    const z0 = 0.1;

    let z = 0;
    if (y >= 0 && y < l1) {
      const fy = gaussian(a2 - a1, l1 / 3, a1, l1, y);
      const fw = gaussian(w2 - w1, l1 / 3, w1, l1, y);
      z = fy * Math.exp(-(Math.pow(x, 2) * 4 * Math.log(fy / z0)) / Math.pow(fw, 2));
    } else if (y >= l1 && y <= l1 + l2) {
      const fy = gaussian(a2 - a3, (l2 - l1) / 3, a3, l1, y);
      const fw = gaussian(w2 - w3, (l2 - l1) / 3, w3, l1, y);
      z = fy * Math.exp(-(Math.pow(x, 2) * 4 * Math.log(fy / z0)) / Math.pow(fw, 2));
    }
    return toFixed2(z);
  }

  /**
   * 生成健康脉的3D数据
   * @param {number} level 水平面高度
   * @param {boolean} showColor 合并颜色
   * @returns {Object} 生成的3D数据对象
   */
  getHealthData(level = 0, showColor = true) {
    const yLen = 41; // x轴坐标长度
    const xMax = 100; // x值得最大值
    const L = [20, 40]; // 关、迟位置
    const A = [14.1, 15.2, 12.9]; // 振幅
    const W = [7.2, 8, 6.8]; // 脉宽
    const { healthColor } = this.config;

    level = level === 0 ? 0 : level - A[1] / 2;

    const plotData = []; // 3D曲面数据
    const scatterData = []; // 漂浮的点 数据
    for (let x = -100; x <= xMax; x += 2) {
      for (let y = 0; y < yLen; y++) {
        const z = level + this.getZ(x / 10, y, L, A, W);
        const dot = [y, toFixed2(x / 10 + 30), toFixed2(z)];
        plotData.push(dot);
        if (Math.abs(x) % 10 === 0) {
          scatterData.push(dot);
        }
      }
    }

    let plot3dDataColor = [];
    if (showColor) {
      // 获取渐变色
      const color = getColor(plotData, ...healthColor);
      // 合并数据
      plot3dDataColor = dataAndColor(plotData, color);
    }

    return { plotData, plot3dDataColor, scatterData };
  }


  /**
 * 通过特征值生成3D数据
 * @param {Array} l 关、迟位置
 * @param {Array} a 振幅
 * @param {Array} w 脉宽
 * @param {boolean} showColor 合并颜色
 * @returns {Object} 生成的3D数据对象
 */
  getFeaturePlotData(l, a, w, showColor = true) {
    const yLen = 41; // x轴坐标长度
    let zMax = 0; // z值得最大值
    const xMax = 10; // x值得最大值
    const { unhealthColor } = this.config;

    const [a1, a2, a3] = a;
    const [w1, w2, w3] = w;

    a = [this.aRange(a1), this.aRange(a2), this.aRange(a3)];
    w = [this.wRange(w1), this.wRange(w2), this.wRange(w3)];

    const plotData = [];
    for (let x = -10; x <= xMax; x += 0.2) {
      for (let y = 0; y < yLen; y++) {
        const z = this.getZ(x, y, l, a, w);
        plotData.push([y, toFixed2(x + 10), z]);
        zMax = z > zMax ? z : zMax;
      }
    }

    let plotColorData = [];
    if (showColor) {
      // 获取渐变色
      const color = getColor(plotData, ...unhealthColor);
      // 合并数据
      plotColorData = dataAndColor(plotData, color);
    }
    // console.log(yLen, 'yLenyLenyLenyLen');

    return { plotData, plotColorData, yLen, zMax };
  }

  /**
   * 通过脉搏波生成3D数据
   * @param {number[]} series 线
   * @param {number} a 振幅
   * @param {number} w 脉宽
   * @param {boolean} showColor 合并颜色
   * @returns {Object} 生成的3D数据对象
   */
  getLinePlotData(series, a, w, showColor = true) {
    const surface = [];
    const scale = this.aRange(a) / 25; // 振幅缩放比例
    // console.log(scale);
    const multiple = 2; // 倍数
    let zMax = 0;
    const xLen = 12 * multiple;
    const { unhealthColor } = this.config;

    for (let x = 0; x < xLen; x++) {
      const arr = [];
      for (const k1 in series) {
        const z = series[k1];
        zMax = z > zMax ? z : zMax;
        arr.push(gause(x - xLen / 2, w, z * scale, multiple));
      }
      surface.push(arr);
    }

    const plotData = [];
    for (let x = 0; x < surface.length; x++) {
      const xArr = surface[x];
      for (let y = 0; y < xArr.length; y++) {
        plotData.push([y, x, toFixed2(xArr[y])]);
      }
    }

    let plotColorData = [];
    if (showColor) {
      // 获取渐变色
      const color = getColor(plotData, ...unhealthColor);
      // 合并数据
      plotColorData = dataAndColor(plotData, color);
    }

    return { plotData, plotColorData, xLen, zMax: zMax * scale };
  }



}


const three = new TyThree()

export const getFeatureOption = (
  L,
  A,
  W,
  totalMai,
  surfaceImg = '',
  cgcImg = '',
  showHealth = false,
  autoRotate = false,
  measure = []
) => {
  // console.log(surfaceImg, cgcImg);
  // console.log(three, 'three')

  const { plotColorData, yLen, zMax } = three.getFeaturePlotData(L, A, W, true);

  // console.log(yLen, 'yLen');


  return getBaseOption(
    plotColorData,
    38,
    yLen,
    zMax,
    totalMai,
    surfaceImg,
    {
      alpha: 15,
      beta: 50,
      distance: 150
    },
    autoRotate,
    cgcImg,
    showHealth,
    measure
  );
};

/**
* 3D 图基础配置
* @param {Object} plotColorData - 绘图数据
* @param {number} xLen - x轴数据长度
* @param {number} yLen - y轴数据长度
* @param {number} zMax - z最大值
* @param {Array<string>} totalMai - 总脉
* @param {string} [surfaceImg] - 水平面图片
* @param {Object} [baseOption] - 视角配置
* @param {boolean} [autoRotate] - 自动旋转
* @param {string} [cgcImg] - 寸关尺图片
* @param {boolean} [showHealth] - 显示健康对比图
* @param {any} [measure] - 测量数据
* @param {string} [key] - 寸关尺
* @returns {Object} - 配置对象
*/
const getBaseOption = (
  plotColorData,
  xLen,
  yLen,
  zMax,
  totalMai,
  surfaceImg,
  baseOption = { alpha: 0, beta: 0, distance: 100 },
  autoRotate,
  cgcImg,
  showHealth = false,
  measure,
  key
) => {
  // console.log('调用方法getBaseOption');

  const boxDepth = showHealth ? 100 : 50;
  const level = getPosition(totalMai, zMax);

  const option = {
    tooltip: { show: false },
    backgroundColor: '#fff',
    grid3D: {
      show: false,
      axisLine: { lineStyle: { color: '#DADFE7', width: 0.5 } },
      axisTick: { show: false },
      axisLabel: { show: false },
      splitLine: { lineStyle: { color: '#DADFE7', width: 0.5 } },
      axisPointer: { show: false },
      postEffect: { screenSpaceAmbientOcclusion: true },
      boxWidth: 90,
      boxHeight: 40,
      boxDepth: boxDepth,
      viewControl: {
        autoRotate: autoRotate,
        projection: 'perspective',
        alpha: baseOption.alpha,
        beta: baseOption.beta,
        minAlpha: 0,
        distance: baseOption.distance,
        center: [0, 0, 0],
        zoomSensitivity: 0,
        rotateSensitivity: [1, 1]
      },
      left: 0,
      top: 0
    },
    xAxis3D: {
      type: 'value',
      min: 0,
      max: yLen - 1,
      name: '',
      splitArea: { show: false }
    },
    yAxis3D: {
      type: 'value',
      name: '',
      splitArea: { show: false }
    },
    zAxis3D: {
      type: 'value',
      min: 0,
      max: 40,
      name: '',
      splitArea: {
        show: true,
        areaStyle: { color: ['#FFFFFF', '#ECECEC'] }
      }
    },
    series: []
  };

  // 3D图
  option.series.push({
    type: 'surface',
    shading: 'color',
    wireframe: { show: false },
    data: plotColorData
  });

  // 水平面
  if (surfaceImg) {
    // console.log(surfaceImg, 'surfaceImg');

    const surfaceData = getSurfaceData(xLen, yLen, level);
    // console.log(surfaceImg, 'surfaceImg1');
    // console.log(surfaceData, 'surfaceData');
    option.series.push({
      type: 'surface',
      shading: 'color',
      colorMaterial: { detailTexture: surfaceImg },
      wireframe: { show: false },
      itemStyle: { color: '#FFFFFF', opacity: 0.35 },
      data: surfaceData
    });
  }

  // 健康对比图
  if (showHealth) {
    const healthData = three.getHealthData(level, measure);
    const healthDataColor = healthData.plot3dDataColor;
    const scatterData = healthData.scatterData;

    option.series.push({
      type: 'surface',
      shading: 'color',
      wireframe: { show: false },
      data: healthDataColor
    });
    // console.log(option.series, ' option.series');


    // 气泡
    option.series.push({
      type: 'scatter3D',
      data: scatterData,
      symbolSize: 4,
      itemStyle: { color: '#5C8ECA' },
      emphasis: { label: { show: false } }
    });
  }

  // 寸关尺
  if (cgcImg) {
    const cgcData = [];
    for (let y = -5; y <= 0; y += 5) {
      for (let x = 0; x <= 38; x += 38) {
        cgcData.push([x, y, 0]);
      }
    }
    option.series.push({
      type: 'surface',
      shading: 'color',
      colorMaterial: { detailTexture: cgcImg },
      wireframe: { show: false },
      itemStyle: { color: '#FFFFFF' },
      data: cgcData
    });
  }
  // console.log(option, 'option3');


  return option;
};

/**
 * 通过脉搏波绘图3D option
 * @param {number[]} series - 线
 * @param {string[]} totalMai - 总脉
 * @param {number} a - 振幅
 * @param {number} w - 脉宽
 * @param {string} [surfaceImg] - 水平面图片
 * @param {any} [measure] - 测量数据
 * @param {string} [key] - 寸关尺
 * @returns {Object} - 3D option 配置
 */
export const getLineOption = (
  series,
  totalMai,
  a,
  w,
  surfaceImg,
  measure,
  key
) => {
  const { plotColorData, xLen, zMax } = three.getLinePlotData(series, a, w, measure);

  return getBaseOption(
    plotColorData,
    xLen,
    series.length,
    zMax,
    totalMai,
    surfaceImg,
    {
      alpha: 30,
      beta: 0,
      distance: 100
    },
    measure,
    key
  );
};

export const getTotalMaiResult = (infos) => {
  const res = { title: [], desc: [] };
  if (!infos) return res;
  infos.forEach((info) => {
    res.title.push(info.name_cn);
    res.desc.push(info.explaination_cn);
  });
  return res;
};




/**
 * 获取分脉结果
 * @param {Object} data - 分脉数据
 * @param {Object} [summaryDesc={cun: '', guan: '', chi: '', summary: []}] - 概要描述
 * @returns {Object} 结果对象
 */
export const getBranchResult = (data, summaryDesc = {
  cun: '',
  guan: '',
  chi: '',
  summary: []
}) => {
  const res = { branch: { ...branchResultDefault }, total: '' };
  if (!data) return res;

  const keys = ['cun', 'guan', 'chi'];
  keys.forEach((key) => {
    res.branch[key] = {
      mai: (summaryDesc[key] || '').replace(/,/g, '、'),
      value: data[key] || ''
    };
  });

  res.total = (summaryDesc.summary || []).join(' ');

  return res;
};

// 分部脉象三部默认值
export const branchResultDefault = {
  cun: branchOneDefalutValue,
  guan: branchOneDefalutValue,
  chi: branchOneDefalutValue,
}

// 分部脉象默认值
export const branchOneDefalutValue = {
  mai: '',
  value: { xian: 50, hua: 50, fu: 50, shu: 50, kong: 50 }
}
