/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-04-06 10:12:41
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-10-30 11:51:32
 * @FilePath: \drant-manager\src\http\moudules\base\member.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/member/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/member/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/member/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/member/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export function bindMemberToDoctor(data) {
  return axios({
    url: basePath + '/member/bindMemberToDoctor',
    method: 'post',
    data
  })
}

export function unBindMemberToDoctor(data) {
  return axios({
    url: basePath + '/member/unBindMemberToDoctor',
    method: 'post',
    data
  })
}

// 会员列表
export const memberFindPage = data => {
  return axios({
    url: 'mgmt/member/pageList',
    method: 'get',
    params: data
  })
}

// 机构会员列表
export const pageListAgent = data => {
  return axios({
    url: 'mgmt/member/pageListAgent',
    method: 'get',
    params: data
  })
}
// 会员的授信额度编辑
export const editMemberCredit = data => {
  return axios({
    url: '/mgmt/member/editMemberCredit',
    method: 'post',
    data
  })
}

// 解冻账户
export const setFreezeStatus = data => {
  return axios({
    url: 'mgmt/member/opsMemberFreezeStatus',
    method: 'post',
    data: data
  })
}

// 手动充值
export const reqRecharge = data => {
  return axios({
    url: 'mgmt/member/activate',
    method: 'post',
    data: data
  })
}

// 查看会员分享关系链路
export function getMemberRelationLinknks(memberId) {
  return axios({
    url: '/mgmt/member/getMemberRelationLink?memberId=' + memberId,
    method: 'get'
  })
}

// 查看邀请关系链路
export function invitationRelation(memberId) {
  return axios({
    url: '/mgmt/member/relation/getMemberRelationLink?memberId=' + memberId,
    method: 'get'
  })
}

// 设置当前会员是否可提现
export function opsMemberWithdrawStatus(data) {
  return axios({
    url: '/mgmt/member/opsMemberWithdrawStatus',
    method: 'post',
    data: data
  })
}

// 设置当前会员魔方出局渠道
export function opsPandoraOutChannel(data) {
  return axios({
    url: '/mgmt/member/opsPandoraOutChannel',
    method: 'post',
    data: data
  })
}

// 获取会员全包信息
export function getMemberWalletAndVip(memberId) {
  return axios({
    url: '/mgmt/member/getMemberWalletAndVip?memberId=' + memberId,
    method: 'get'
  })
}

// 查询会员钱包流水
export function getMemberWalletLog(data) {
  return axios({
    url: '/mgmt/member/getMemberWalletLog',
    method: 'get',
    params: data
  })
}

// 设置当前会员是否可提现
export function opsMemberQvwStatus(data) {
  return axios({
    url: '/mgmt/member/opsMemberCpClient',
    method: 'post',
    data: data
  })
}

// 换绑上级店主
export const changeParentShopkeeper = data => {
  return axios({
    url: '/mgmt/member/relation/changeParentShopkeeper',
    method: 'post',
    data
  })
}

// 上级店主是我的用户
export const getParentShopkeeperUsers = data => {
  return axios({
    url: '/mgmt/member/relation/getParentShopkeeperUsers',
    method: 'get',
    params: data
  })
}

// 下级店主列表
export const getSubShopkeepers = data => {
  return axios({
    url: '/mgmt/member/relation/getSubShopkeepers',
    method: 'get',
    params: data
  })
}
