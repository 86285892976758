import axios from '../../axios'

// 获取图片验证码

export const getImgCode = (data) => {
  return axios({
    url: '/api/captcha/images',
    method: 'post',
    params: data
  })
}

export const pulseDiagnosis = (data) => {
  return axios({
    url: '/api/pulseDiagnosis/detail/' + data.measureId,
    method: 'post',
    params: data
  })
}
