import axios from '../../axios'

// 获取app列表
export function getAppList(data) {
  return axios({
    url: '/mgmt/mini/program/getPage',
    method: 'get',
    params: data
  })
}

export function programAdd(data) {
  return axios({
    url: '/mgmt/mini/program/add',
    method: 'post',
    data: data
  })
}

export function programEdit(data) {
  return axios({
    url: '/mgmt/mini/program/edit',
    method: 'post',
    data: data
  })
}

export function getProperty(data) {
  return axios({
    url: '/mgmt/mini/program/property/getProperty',
    method: 'post',
    data: data
  })
}

export function propertySave(data) {
  return axios({
    url: '/mgmt/mini/program/property/save',
    method: 'post',
    data: data
  })
}

export function mappingUpdate(data) {
  return axios({
    url: '/mgmt/company/sepAcct/mapping/update',
    method: 'post',
    data: data
  })
}

// 获取app列表
export function getMapping(data) {
  return axios({
    url: '/mgmt/company/sepAcct/mapping/detail/' + data.appId,
    method: 'get',
    params: data
  })
}
