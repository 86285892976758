<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
    <div class="right-menu">
      <!-- <div slot="reference" class="bell_info" @click="upNotifList">
        <div class="bell_icon">
          <img v-show="!total" src="@/assets/bell-no.png">
          <img v-show="!!total" src="@/assets/bell.png">
        </div>
        <span v-show="!!total"><i>{{ total }}</i></span>
      </div> -->
      <YkjNotices jump-page="/recipecheckpharmacist/checkpharmacist" />
      <div class="user-info">
        <div class="user-info-cont">
          <div class="user-info-head">
            <el-avatar :size="32" :src="currentUserInfo ? currentUserInfo.wxDoctorHeadimg : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" />
          </div>
          <div>
            <div class="user-info-text">{{ currentUserInfo.userName }}</div>
            <div v-if="!roles.length" class="user-info-tab">{{ currentUserInfo.isSuperUser === 1 ? '超级管理员' : currentRole.roleName }}</div>
            <div v-if="!roles.length && currentUserInfo.auditorLevel" class="user-info-tab">{{ currentUserInfo.auditorLevel === 1 ? '一级审核人' : '二级审核人' }}</div>
            <el-dropdown v-else trigger="click">
              <div class="user-info-tab">{{ currentUserInfo.isSuperUser === 1 ? '超级管理员' : currentRole.roleName }}</div>
              <div v-if="currentUserInfo.auditorLevel" class="user-info-tab">{{ currentUserInfo.auditorLevel === 1 ? '一级审核人' : '二级审核人' }}</div>
              <el-dropdown-menu>
                <template v-for="role in roles">
                  <el-dropdown-item v-if="role.id != currentRole.roleId" :key="role.id" @click.native="changeRole(role)">
                    <span style="display:block;">{{ role.name }}</span>
                  </el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <el-dropdown trigger="click">
        <i class="el-icon-caret-bottom el-icon--right" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">注销</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="updPassword">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 实时消息提醒框 -->
    <!-- <div v-if="newMessage" class="message_box_pop">
      <div><i class="el-icon-info" style="color: #3d68cc;font-size: 16px;" /> 通知 <i class="el-icon-close r" @click="newMessage=false" /></div>
      <div>您有新的处方待审核，请及时处理！</div>
      <div>
        <el-button type="primary" size="mini" @click="jump">立即处理</el-button>
      </div>
    </div> -->
    <!-- 通知列表 -->
    <!-- <div v-show="pop" id="el-popover" class="el-popover el-popper" tabindex="0" style="width: 280px;" x-placement="bottom" @mouseleave="pop=false">
      <div class="info_box">
        <p class="t">通知</p>
        <div v-if="!infoList.length" class="no-data"><img src="@/assets/no-data.png" alt="" style="height: 160px"><div>暂时没有收到通知哦</div></div>
        <ul v-show="!!infoList.length" v-infinite-scroll="load" class="infinite-list" style="overflow:auto" infinite-scroll-distance="1">
          <li v-for="item in infoList" :key="item.id" @click="selInfo(item)">
            <div>
              <p>{{ item.content }}</p>
              <p>{{ item.createTime }}</p>
            </div>

          </li>
        </ul>
      </div>
      <div x-arrow="" class="popper__arrow" style="left: 132.5px;" />
    </div> -->
    <dialogNoteCode ref="dialogNoteCode2" @cb="up" />
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
import { mapGetters } from 'vuex'
// import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import dialogNoteCode from '@/components/dialogNoteCode'
// import $websocket from '@/utils/websocket'
// import YkjNotices from './ykj-notices/YkjNotices'
import { setToken, removeToken } from '@/utils/auth'
export default {
  components: {
    Hamburger,
    dialogNoteCode
    // YkjNotices
  },
  data() {
    return {
      count: 0,
      pop: false,
      latestDate: 0, // 最新时间
      newMessage: false,
      tt: null, // 定时器
      lockReconnect: false, // 避免重复连接
      realName: '',
      userInfo: {
        userName: '',
        currentRoleName: '',
        isSuperUser: ''
      },
      pageVo: {
        current: 1,
        pages: 0,
        total: 0,
        size: 10
      },
      total: 0,
      curPage: 1,
      infoList: [
        // {
        //   content: '1',
        //   createTime: new Date(),
        //   id: 1,
        //   link: 'baidu.com'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device', 'name', 'currentRole', 'roles', 'currentUserInfo'])
  },
  created() {
    this.getCurrentUser()
    // this.messageCount()
  },
  methods: {
    // load() {
    //   if (this.pageVo.total && this.pageVo.current < this.pageVo.pages) {
    //     this.notifications()
    //   }
    // },
    // upNotifList() {
    //   this.pop = true//! this.pop
    //   if (this.pop) {
    //     this.notifications(1)
    //   }
    // },
    // 跳转审核列表
    // jump() {
    //   this.newMessage = false
    //   this.$router.push(`/recipecheckpharmacist/checkpharmacist`)
    // },
    // 长轮询统计当前用户消息通知
    // messageCount() {
    //   this.$api.user.messageCount().then((res) => {
    //     if (res.code === 200) {
    //       const data = res.data || {}
    //       this.total = data.total

    //       if (this.latestDate !== data.latestDate) {
    //         this.newMessage = !!data.total
    //       }
    //       this.latestDate = data.latestDate
    //       this.messageCount()
    //     }
    //   })
    // },
    // 消息通知列表
    // notifications(curPage) {
    //   if (!curPage) {
    //     this.pageVo.current++
    //   } else {
    //     this.curPage = curPage
    //   }
    //   console.log(this.pageVo.current)
    //   const params = {
    //     curPage: this.pageVo.current,
    //     pageSize: 10
    //   }
    //   this.$api.user.notifications(params).then((res) => {
    //     if (res.code === 200) {
    //       const data = res.data || {}
    //       this.pageVo = res.data
    //       if (this.pageVo.current === 1) {
    //         this.infoList = data.records || []
    //       } else {
    //         this.infoList = this.infoList.concat(data.records)
    //       }
    //     }
    //   })
    // },
    // 消息通知已读
    // notificationsRead(id) {
    //   this.$api.user.notificationsRead(id)
    // },
    // 选择消息
    // selInfo(item) {
    //   this.notificationsRead(item.id)
    //   // 跳到具体审核详情页
    //   this.$router.push(item.link)
    // },
    up() {
      this.$router.push(`/dashboard/dashboard?t=` + new Date().getTime())
    },
    getCurrentUser() {
      this.$api.user.getCurrentUser().then(res => {
        console.log(res, '个人信息')
        // console.error('mmmmmmmmmmmmmm', this.currentRole)
        if (res.data.realName) {
          this.realName = res.data.realName
        }
        if (this.currentRole.roleCode === 'patient' || this.currentRole.roleCode === 'business') {
          sessionStorage.clear() // 清除本地缓存
          removeToken()
          // Cookies.remove('token')
          this.$store.commit('user/SET_PERMSS', [])
          this.$store.commit('permission/SET_MENUS', [])
          this.$store.commit('user/SET_ROLES', [])
          this.$store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
          this.$store.commit('app/webReloadLoginFlag', false) // 重新登入为false
          this.$store.commit('app/errorNoAuthCount', 0)
          this.$store.commit('user/SET_CURRENT_ROLE', {}) // 设置当前角色
          this.$store.commit('user/SET_TOKEN', '')
          MessageBox.alert('系统暂不支持患者和商务重新登录！', '重新登录', {
            confirmButtonText: '确定',
            callback: action => {
              location.reload()
            }
          })
          return
        }
        if (res.code === 200 && res.data) {
          const user = res.data
          if (!user.wxDoctorHeadimg) {
            user.wxDoctorHeadimg = this.$imgpath +'/kefu.png'
          }
          this.$store.commit('user/SET_USER', user)
          // $websocket.initSocket(user.id)
          this.userInfo = res.data
        }
      })
    },
    sendHeadMsg() {
      // $websocket.sendMessageCheck({ type: 3, content: 'pong' }, res => {
      //   console.log('000000000000000')
      // })
    },
    updPassword() {
      this.$emit('updPassword', true)
    },
    async changeRole(role) {
      console.log('bbbbbbbbb', role)
      if (role.roleCode === 'patient' || role.roleCode === 'business') {
        return
      }
      const sysUserParam = {
        roleCode: role.roleCode
      }
      await this.$api.user.changeRole(sysUserParam).then(res => {
        if (res.code === 200) {
          setToken(res.data)
          this.$store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
          this.$store.commit('user/SET_PERMSS', [])
          this.$store.commit('permission/SET_MENUS', [])
          this.$store.commit('user/SET_ROLES', [])
          this.$store.commit('user/SET_TOKEN', res.data) // 设置token
          this.$store.commit('user/SET_CURRENT_ROLE', {}) // 设置当前角色dashboard
          // sessionStorage.setItem('user', '')
          this.$api.user
            .getCurrentUser()
            .then(res => {
              this.$router.push(`/dashboard/dashboard?t=` + new Date().getTime())
            })
            .catch(res => {
              if (res.data.code === 300402) {
                this.$refs.dialogNoteCode2.init()
              } else if (res.data.code === 300401) {
                const join = {
                  redirectUrl: window._conf.baseApi
                }
                const _token = sessionStorage.getItem('token')
                this.$api.login.getFace(join, _token).then(res => {
                  if (res.code === 200) {
                    console.log(res)
                    // sessionStorage.setItem('temporaryTOKEN', token)
                    sessionStorage.setItem('facer', true)
                    window.location.href = res.data
                  }
                })
              }
            })

          // $websocket.initCurrentUserSocket(() => {
          //   console.error('当前聊天websocket状态:', this.chatSocket.readyState)
          // })
        }
      })
    },
    cpassword() {
      this.changedown = true
      this.$emit('cpassword', true)
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      // $websocket.websocketClose()
      await this.$api.login.logout().then(res => {
        if (res.code === 200) {
          sessionStorage.clear() // 清除本地缓存
          // Cookies.remove('token')
          removeToken()
          this.$store.commit('user/SET_PERMSS', [])
          this.$store.commit('permission/SET_MENUS', [])
          this.$store.commit('user/SET_ROLES', [])
          this.$store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
          this.$store.commit('user/SET_TOKEN', '') // 设置token
          this.$store.commit('user/SET_CURRENT_ROLE', {}) // 设置当前角色
          // this.$router.push(`/login`)
          this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        }
        // this.loading = false
      })
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
// .no-data{
//   text-align: center;
//   // line-height: 50px;
//   padding-bottom: 80px;
// }

// .el-popover{
//     transform-origin: center top;
//     z-index: 2017;
//     position: fixed;
//     top: 48px;
//     right: 70px;
//     border-radius: 10px;
// }
// .el-popper[x-placement^=bottom] {
//     margin-top: 12px;
// }
// .message_box_pop{
//   position: fixed;
//   bottom: 60px;
//   right: 20px;
//   width: 290px;
//   padding: 14px 20px 14px 13px;
//   border-radius: 8px;
//   box-sizing: border-box;
//   border: 1px solid #ebeef5;
//   position: fixed;
//   background-color: #fff;
//   box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
//   transition: opacity .3s,transform .3s,left .3s,right .3s,top .4s,bottom .3s;
//   overflow: hidden;
//   text-align: right;
//   .r{
//     float: right;
//     cursor: pointer;
//   }
//   div{
//     line-height: 20px;
//     font-size: 14px;
//   }
//   div:nth-of-type(1){
//     text-align: left;
//   }
//   div:nth-of-type(2){
//     margin: 10px 0;
//     color: #999;
//   }
// }
// .info_box{
//   p{
//     margin: 5px;
//   }
//   .t{
//     // background: #f7f7f7;
//     font-size: 15px;
//     height: 50px;
//     line-height: 35px;
//     margin: 0;
//     padding: 8px 10px;
//     color: #000000;
//     opacity: 0.9;
//     font-weight: bold;
//     border-bottom: 1px solid #E5E6EB;
//   }
//   ul{
//     padding-left: 0;
//     padding-right: 0;
//     list-style: none;
//     height: 200px;
//     margin-top: 8px;
//     margin-bottom: 30px;
//     overflow: auto;
//     li{
//       padding: 0 15px;
//       cursor: pointer;
//       &:hover{
//         background:#F4F4F4;
//       }
//       div{
//         border-bottom: 1px solid #f2f2f2;
//         padding-top: 5px;
//       }
//     }
//   }
// }
// .bell_info{
//   position: relative;
//   // font-size: 30px;
//   color: #fff;
//   margin-right: 20px;
//   cursor: pointer;
//   span{
//     position: absolute;
//     left: 16px;
//     font-size: 12px;
//     min-width: 19px;
//     height: 14px;
//     top: -1px;
//     text-align: center;
//     border: 1px solid rgba(255,255,255,0.7);
//     line-height: 1;
//     background: linear-gradient(149deg, #FF5F5F 0%, #F52424 100%);
//     border-radius: 10px 10px 10px 10px;
//     i{
//       font-style: normal;
//       scale: 0.8;
//       /* transform: scale(0.5); */
//       display: inline-block;
//     }
//   }
//   .bell_icon{
//     width: 28px;
//     height: 28px;
//     // background: url('~@/assets/bell.png') no-repeat left top / contain;;
//     img{
//       width: 28px;
//     }
//   }
// }
.xian {
  display: inline-block;
  width: 1px;
  height: 15px;
  background: #fff;
  position: relative;
  margin-left: 20px;
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #163997;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding-bottom: 5px;

  .hamburger-container {
    line-height: 50px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }
    .avatar-container {
      margin-left: 10px;
      .avatar-wrapper {
        position: relative;
      }
    }
  }
}
.el-icon-caret-bottom {
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  padding: 0 24px 0 16px;
}
.user {
  display: flex;
  align-items: center;
}
.user-avatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}
.el-dropdown-menu__item--divided {
  margin-top: 0 !important;
  border-top: none;
}
.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 3 !important;
}
.active-a {
  display: none;
}
.user-info {
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  i {
    margin-left: 32px;
    line-height: 50px;
    font-size: 20px;
  }
  .user-info-cont {
    height: 50px;
    padding: 9px 0;
    display: flex;
    line-height: 1;
    .user-info-head {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
    .user-info-text {
      font-size: 14px;
      color: #fff;
      margin-bottom: 2px;
    }
    .user-info-tab {
      padding: 2px 4px;
      background: #fff;
      color: #163997;
      border-radius: 2px;
      font-size: 12px;
      display: inline-block;
    }
  }
}
</style>
