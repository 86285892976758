import axios from '../../axios'



export const pulseDiagnosis = (data) => {
  return axios({
    url: '/api/pulseDiagnosis/detail/' + data.measureId,
    method: 'get',
    params: data
  })
}
