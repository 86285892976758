import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'

// 后台管理员使用助理师列表
export function findPage(data) {
  return axios({
    url: '/mgmt/cloud/assistant/pageList',
    method: 'get',
    params: data
  })
}
// 机构使用助理师列表
export function findPageAgent(data) {
  return axios({
    url: '/mgmt/cloud/assistant/pageListAgent',
    method: 'get',
    params: data
  })
}
// 新增助理师
export function assistantAdd(data) {
  return axios({
    url: '/mgmt/cloud/assistant/add',
    method: 'post',
    data
  })
}
// 编辑助理师
export function assistantUpdate(data) {
  return axios({
    url: '/mgmt/cloud/assistant/update',
    method: 'post',
    data
  })
}
// 获取用户列表
export function memberPageList(data) {
  return axios({
    url: '/core/member/pageList',
    method: 'get',
    params: data
  })
}
// 绑定患者二维码
export function bindQR(data) {
  return axios({
    url: '/mgmt/wxmini-qrcodes/_createQRCode',
    method: 'post',
    data
  })
}