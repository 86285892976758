import axios from '../../axios'

// 子商户列表
export const getSubMerchantPageList = data => {
  return axios({
    url: '/mgmt/payment/merchant/mySubMerchantPageList',
    method: 'get',
    params: data
  })
}

// 新增子商户
export const openAccountAuthorize = data => {
  return axios({
    url: '/mgmt/quick/money/registration/openAccountAuthorize',
    method: 'post',
    data
  })
}

// 子商户进件列表
export const getInputPieceList = data => {
  return axios({
    url: '/mgmt/payment/submit/doc/pageList',
    method: 'get',
    params: data
  })
}


// 合同签约
export const contractSign = data => {
  return axios({
    url: '/mgmt/quick/money/registration/contractSign',
    method: 'post',
    data
  })
}

// 合同查询
export const contractQuery = data => {
  return axios({
    url: '/mgmt/quick/money/registration/contractQuery',
    method: 'post',
    data
  })
}

// 资质上传
export const uploadCertApply = data => {
  return axios({
    url: '/mgmt/quick/money/registration/uploadCertApply',
    method: 'post',
    data
  })
}

// 新商户进件
export const merchantSubmitDoc = data => {
  return axios({
    url: '/mgmt/quick/money/registration/merchantSubmitDoc',
    method: 'post',
    data
  })
}

// 进件附属材料查询
export const additionQuery = data => {
  return axios({
    url: '/mgmt/quick/money/registration/additionQuery',
    method: 'post',
    data
  })
}

// 获取新商户进件
export const getMerchantSubmitDoc = data => {
  return axios({
    url: '/mgmt/payment/submit/doc/detail/' + data.id,
    method: 'get',
    params: data
  })
}

  // 更新进件订单状态
  export const submitDocOrderQuery = data => {
    return axios({
      url: '/mgmt/quick/money/registration/submitDocOrderQuery',
      method: 'post',
      data
    })
  }

  // 子商户余额
export const getSubBalance = data => {
  return axios({
    url: '/mgmt/quick/money/merchant/balance',
    method: 'get',
    params: data
  })
}

  // 提现余额
  export const withdrawSubBalance = data => {
    return axios({
      url: '/mgmt/quick/money/merchant/withdraw',
      method: 'post',
      data
    })
  }