import axios from '../../axios'

// 商户列表
export const getMerchantPageList = data => {
  return axios({
    url: '/mgmt/payment/merchant/pageList',
    method: 'get',
    params: data
  })
}

// 新增商户
export const merchantAdd = data => {
  return axios({
    url: '/mgmt/payment/merchant/add',
    method: 'post',
    data
  })
}

// 编辑商户
export const merchantUpdate = data => {
  return axios({
    url: '/mgmt/payment/merchant/update',
    method: 'post',
    data
  })
}


// 商户应用列表
export const getMerchantAppPageList = data => {
  return axios({
    url: '/mgmt/payment/merchant/app/pageList',
    method: 'get',
    params: data
  })
}

// 新增商户应用
export const merchantAppAdd = data => {
  return axios({
    url: '/mgmt/payment/merchant/app/add',
    method: 'post',
    data
  })
}

// 编辑商户应用
export const merchantAppUpdate = data => {
  return axios({
    url: '/mgmt/payment/merchant/app/update',
    method: 'post',
    data
  })
}

// 支付配置列表
export const getConfigPageList = data => {
  return axios({
    url: '/mgmt/payment/config/pageList',
    method: 'get',
    params: data
  })
}

// 新增支付配置
export const configAdd = data => {
  return axios({
    url: '/mgmt/payment/config/add',
    method: 'post',
    data
  })
}

// 编辑支付配置
export const configUpdate = data => {
  return axios({
    url: '/mgmt/payment/config/update',
    method: 'post',
    data
  })
}

// 支付渠道列表
export const getChannelPageList = data => {
  return axios({
    url: '/mgmt/payment/channel/pageList',
    method: 'get',
    params: data
  })
}

// 新增支付渠道
export const channelAdd = data => {
  return axios({
    url: '/mgmt/payment/channel/add',
    method: 'post',
    data
  })
}

// 编辑支付配置
export const channelEdit = data => {
  return axios({
    url: '/mgmt/payment/channel/edit',
    method: 'post',
    data
  })
}

// 支付渠道是否禁用
export const channelOpsStatus = data => {
    return axios({
      url: '/mgmt/payment/channel/opsStatus',
      method: 'post',
      data
    })
  }
