import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'

// 后台管理员使用店铺列表
export function findPage(data) {
  return axios({
    url: '/mgmt/shopkeeper/shops/pageList',
    method: 'get',
    params: data
  })
}
// 新增店铺
export function shopsAdd(data) {
  return axios({
    url: '/mgmt/shopkeeper/shops/add',
    method: 'post',
    data
  })
}
// 编辑店铺
export function shopsUpdate(data) {
  return axios({
    url: '/mgmt/shopkeeper/shops/update',
    method: 'post',
    data
  })
}
// 获取用户列表
export function memberPageList(data) {
  return axios({
    url: '/core/member/pageList',
    method: 'get',
    params: data
  })
}

// 删除店铺
export function shopsDelete(data) {
  return axios({
    url: '/mgmt/shopkeeper/shops/delete/' + data.id,
    method: 'post',
    data
  })
}

// 店铺流水
export function getFlowPage(data) {
  return axios({
    url: '/mgmt/order/shopkeeper/shop/flow/getPage',
    method: 'get',
    params: data
  })
}

// 店铺收款码列表
export function receiptCodeList(data) {
  return axios({
    url: '/mgmt/shopkeeper/shops/receiptCode/pageList',
    method: 'get',
    params: data
  })
}
// 新增店铺收款码
export function receiptCodeAdd(data) {
  return axios({
    url: '/mgmt/shopkeeper/shops/receiptCode/add',
    method: 'post',
    data
  })
}
// 编辑店铺收款码
export function receiptCodeUpdate(data) {
  return axios({
    url: '/mgmt/shopkeeper/shops/receiptCode/update',
    method: 'post',
    data
  })
}
// 删除店铺收款码
export function receiptCodeDelete(data) {
  return axios({
    url: '/mgmt/shopkeeper/shops/receiptCode/delete/' + data.id,
    method: 'post',
    data
  })
}

// 生成收款二维码
export function bindQR(data) {
  return axios({
    url: '/mgmt/wxmini-qrcodes/_createQRCode',
    method: 'post',
    data
  })
}
