<template>
  <div class="mobile" v-if="data">
    <div style="">
      <div class="mai-item stability">
        <h1 class="title">测量稳定指数：{{ data.score }}%</h1>
        <div class="paragraph-wrap">
          <!---->
          <div class="paragraph">
            <p class="paragraph-p">若稳定指数低于60%，脉象结果不建议采用，可重新测量。</p>
            <p class="paragraph-p">注意测量过程中避免说话、身体晃动等。</p>
          </div>
        </div>
      </div>
      <div class="page-top gray">
        <div class="print-user">
          <div class="portrait"
            style="background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/wxmini/static/common/huanzhetx.png')">
          </div>
          <div class="info">
            <div class="name">
              <strong><!----></strong>
              <span></span>
              <span style="display: none"></span>
              <span></span>
            </div>
            <div>{{ data.createTime || '--' }}</div>
            <div class="hand" style="">
              <span style="display: none">Both hands</span>
              <span>{{ data.hand == 2 ? '双手' : '单手' }}</span>
            </div>
          </div>
        </div>
        <div class="title" style="display: none">
          <h1>Health report</h1>
          <h2>Intelligent pulse diagnosis system</h2>
        </div>
        <div class="">
          <div style="font-size: 26px; font-weight: 700">健康报告</div>
          <div style="text-align: right">智能脉诊系统</div>
        </div>
      </div>
    </div>
    <div class="mai-item mai-desc" style="">
      <div class="desc">
        <h1 class="title">
          <div>总体脉象：{{ talSummary }}</div>
        </h1>
        <div class="heart-rate img-heart-rate">心率：{{ data.heartRate }}次/分钟</div>
        <p>注释:</p>
        <p v-for="(item, index) in data.mais.summary_infos" :key="index">{{ item.explaination_cn }}</p>
        <!-- <p>{{ data.mais.summary_infos[1].explaination_cn }}</p>
        <p>{{ data.mais.summary_infos[2].explaination_cn }}</p> -->
      </div>
      <div class="mai-hand">
        <div class="img-hand right"></div>
        <div class="img-hand left"></div>
      </div>
    </div>

    <!-- 左手 -->
    <div class="mai-item mai-3d" v-if="totalMai">
      <h1 class="title">左手脉象：{{ branchResult.left.total }}</h1>
      <Total3d :feature="feature.left" :width="channelWidth" :total-mai="totalMai.title" />
    </div>

    <div style="" v-if="branchResult">
      <div class="mai-item mai-branch">
        <h1 class="title">
          <span>左手分部脉象</span>
          <div class="branch-legend">
            <div class="unhealthy">异常区</div>
            <div>健康区</div>
          </div>
        </h1>
        <BranchGroup :branch-result="branchResult.left" :feature="feature.left" :width-bar="150" :width3d="150 * 2.3" />

        <div class="hole-body" style="margin-top: 3rem">
          <div style="">
            <div style="display: flex">
              <div class="handimg">
                <img
                  src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/lefthand.png"
                  alt="" class="mypic" />
              </div>
              <div>
                <div class="mai-all">
                  <div class="mai-font">
                    <img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/cun_top.png"
                      alt="" />
                  </div>
                  <img
                    src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png"
                    alt="" />
                  <img :src="data.dataLeft.heatMap.heatmap_img_cun_url" alt="" class="mai-img"
                    style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">左寸</span>
                  <div class="mai-color-image"><img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png"
                      alt="" /></div>
                </div>
                <div class="mai-all">
                  <div class="mai-font"><img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/gun_top.png"
                      alt="" /></div>
                  <img
                    src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png"
                    alt="" />
                  <img :src="data.dataLeft.heatMap.heatmap_img_guan_url" alt="" class="mai-img"
                    style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">左关</span>
                  <div class="mai-color-image"><img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png"
                      alt="" /></div>
                </div>
                <div class="mai-all">
                  <div class="mai-font">
                    <img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/chi_top.png"
                      alt="" />
                  </div>
                  <img
                    src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png"
                    alt="" />
                  <img :src="data.dataLeft.heatMap.heatmap_img_chi_url" alt="" class="mai-img"
                    style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">左尺</span>
                  <div class="mai-color-image">
                    <img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png"
                      alt="" class="mai-color-img" />
                  </div>
                </div>
                <img
                  src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/waiya.png"
                  alt="" class="timeWidth" style="width: 88%" />
              </div>
            </div>
            <div style="display: flex; width: 100%">
              <div class="far">
                <div>
                  <div>外</div>
                  <div>压</div>
                </div>
              </div>
              <div class="chart-branch-3d2" v-if="isgetLeftHeatmap">
                <Chart :x="xLeftValue" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右手 -->
    <div class="mai-item mai-3d" v-if="branchResult">
      <h1 class="title">右手脉象：{{ branchResult.right.total }}</h1>
      <Total3dRight :feature="feature.right" :width="channelWidth" :total-mai="totalMai.title" />
    </div>

    <div style="" v-if="branchResult">
      <div class="mai-item mai-branch">
        <h1 class="title">
          <span>右手分部脉象</span>
          <div class="branch-legend">
            <div class="unhealthy">异常区</div>
            <div>健康区</div>
          </div>
        </h1>
        <BranchGroup :branch-result="branchResult.right" :feature="feature.right" :width-bar="150"
          :width3d="150 * 2.3" />

        <div class="hole-body" style="margin-top: 3rem">
          <div style="">
            <div style="display: flex">
              <div class="handimg">
                <img
                  src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/lefthand.png"
                  alt="" class="mypic" />
              </div>
              <div>
                <div class="mai-all">
                  <div class="mai-font">
                    <img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/cun_top.png"
                      alt="" />
                  </div>
                  <img
                    src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png"
                    alt="" />
                  <img :src="data.dataRight.heatMap.heatmap_img_cun_url" alt="" class="mai-img"
                    style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">右寸</span>
                  <div class="mai-color-image"><img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png"
                      alt="" /></div>
                </div>
                <div class="mai-all">
                  <div class="mai-font"><img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/gun_top.png"
                      alt="" /></div>
                  <img
                    src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png"
                    alt="" />
                  <img :src="data.dataRight.heatMap.heatmap_img_guan_url" alt="" class="mai-img"
                    style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">右关</span>
                  <div class="mai-color-image"><img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png"
                      alt="" /></div>
                </div>
                <div class="mai-all">
                  <div class="mai-font">
                    <img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/chi_top.png"
                      alt="" />
                  </div>
                  <img
                    src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png"
                    alt="" />
                  <img :src="data.dataRight.heatMap.heatmap_img_chi_url" alt="" class="mai-img"
                    style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">右尺</span>
                  <div class="mai-color-image">
                    <img
                      src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png"
                      alt="" class="mai-color-img" />
                  </div>
                </div>
                <img
                  src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/waiya.png"
                  alt="" class="timeWidth" style="width: 88%" />
              </div>
            </div>
            <div style="display: flex; width: 100%">
              <div class="far">
                <div>
                  <div>外</div>
                  <div>压</div>
                </div>
              </div>
              <div class="chart-branch-3d2" v-if="isgetRightHeatmap">
                <Chart :x="xRightValue" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 六经辩证 -->
    <div class="mai-item mai-branch" v-if="channelReault">
      <h1 class="title">
        六经辨证
        <span class="show-img" @click="showImgFn">查看大图</span>
      </h1>
      <div :class="{ 'channel-big-img': isShowImg }">
        <i class="icon-close" :style="{ display: isShowImg ? 'initial' : 'none' }" @click="showImgFn"></i>
        <ChannelChart :channels="channelReault" :heartrate="heartrate" :summary="summary"
          :width="isShowImg ? 700 : channelWidth" />
      </div>

      <ChannelParagraph :channels="channelReault" :symptoms="symptomsResult" />
    </div>

    <!-- 气血津液 -->
    <div class="mai-item" v-if="qxjyResult">
      <h1 class="title">气血津液</h1>
      <QxjyChart :value="qxjyResult.level" />
      <QxjyParagraph :qxjy="qxjyResult" />
    </div>

    <!-- 邪气 -->
    <div class="mai-item" v-if="heresyResult">
      <h1 class="title">邪气</h1>
      <HeresyIcon :value="heresyResult.value" />
      <HeresyParagraph :heresy="heresyResult" />
    </div>

    <!-- 建议 -->
    <div class="mai-item" style="" v-if="healthAdvice">
      <h1 class="title">养生建议</h1>
      <div class="paragraph-wrap">
        <div class="paragraph">
          <h2 class="sub-title">饮食</h2>
          <p class="paragraph-p">{{ healthAdvice.new_food_propose }}</p>
          <h2 class="sub-title">运动</h2>
          <p class="paragraph-p">{{ healthAdvice.new_sport_propose }}</p>
          <h2 class="sub-title">生活</h2>
          <p class="paragraph-p">{{ healthAdvice.other_propose }}</p>
          <h2 class="sub-title" style="display: none">Nothing</h2>
          <h2 class="sub-title" style="display: none">无</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Total3d from './components/total3d.vue'
import Total3dRight from './components/total3dRight.vue'
import BranchGroup from './components/Branch/Group.vue'
import Chart from './components/Branch/Chart.vue'
import ChannelChart from './components/ChartM.vue'
import ChannelParagraph from './components/Paragraph.vue'
import QxjyChart from './components/QxjyChart/QxjyChart.vue'
import QxjyParagraph from './components/QxjyChart/QxjyParagraph.vue'
import HeresyIcon from './components/HeresyIcon/Icon.vue'
import HeresyParagraph from './components/HeresyIcon/paragraph.vue'
import { getTotalMaiResult, getBranchResult } from './components/three.js'
import { getChannelResult, getSymptomsResult, getQxjyResult, getHeresyResult } from './components/maiResult.js'
import 'whatwg-fetch'

export default {
  components: {
    Total3d,
    Total3dRight,
    Chart,
    BranchGroup,
    ChannelChart,
    ChannelParagraph,
    QxjyChart,
    QxjyParagraph,
    HeresyIcon,
    HeresyParagraph
  },
  data() {
    return {
      talSummary: null,
      isShowImg: false,
      healthAdvice: null,
      qxjyResult: null,
      heresyResult: null,
      totalMai: null,
      channelReault: null,
      heartrate: null,
      summary: null,
      symptomsResult: null,
      data:null,
      webviewStyles: {
        progress: {
          color: '#8362DF'
        }
      },
      handtype: '',
      ppgBack: '',
      essayUrl: '',
      channelWidth: 500,
      feature: {},
      branchResult: null,
      xLeftValue: [],
      xRightValue: [],
      isgetLeftHeatmap: false,
      isgetRightHeatmap: false
    }
  },
  computed: {
    // channelWidth() {
    //   if (this.channelWidth > 500) {
    //     return 500;
    //   } else if (this.channelWidth < 320) {
    //     return 288;
    //   } else {
    //     return this.channelWidth * 0.9;
    //   }
    // }
  },
  methods: {
    showImgFn() {
      this.isShowImg = !this.isShowImg
    },
    back() {
      getApp().globalData.navigator('back')
    },
    // 左手
    async getLeftHeatmap(a) {
      const res = await this.fGetHeatmapData(a)

      const xSarr = []
      res.x.forEach((i, ind) => {
        xSarr.push([i / 1000, res.y[ind] / 100])
      })

      this.xLeftValue = xSarr
      this.isgetLeftHeatmap = true
    },
    // 右手
    async getRightHeatmap(a) {
      const res = await this.fGetHeatmapData(a)

      const xSarr = []
      res.x.forEach((i, ind) => {
        xSarr.push([i / 1000, res.y[ind] / 100])
      })

      this.xRightValue = xSarr
      this.isgetRightHeatmap = true
    },
    // 定义一个函数来获取数据
    async fGetHeatmapData(resUrl) {
      const url = 'https://api.reborn-tech.com/tycenter/heatmap/data?resUrl=' + resUrl

      try {
        // 发送请求
        const response = await fetch(url)

        // 检查响应是否成功
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        // 解析响应数据为 JSON
        const data = await response.json()

        // 打印数据到控制台（或者你可以进行其他处理）
        // console.log(data, 'fGetHeatmapData')

        // 处理数据
        return data
      } catch (error) {
        // 捕捉错误并打印
        console.error('Error fetching heatmap data:', error)
      }
    }
  },
  mounted() {
    document.title = '检测报告'
  },
  created() {
    const screenWidth = window.innerWidth
    let measureId = this.$route.query.measureId || null
    if (!measureId) {
      this.$message({ message: '查询对象不在。', type: 'error' })
      return
    }
    // console.log(JSON.parse(this.result),'totalMai');
    this.$api.pulse.pulseDiagnosis({ measureId }).then(res => {
      if (res.code === 200) {

        if (!res.data) {
          this.$message({ message: '查询对象错误。', type: 'error' })
          return
        }
        // console.log('res报告', res);
        let obj = res.data
        Object.keys(obj).forEach(key => {
          if (key != 'lastUpdateTime' && key != 'createTime' && key != 'measureId' && key != 'reportUrl') {
            obj[key] = JSON.parse(obj[key]);
          }
        });
        // console.log(' obj报告', obj);
        this.data = obj
        this.channelWidth = screenWidth > 500 ? 500 : screenWidth < 320 ? 288 : screenWidth * 0.9
        let str = this.data.summaryDesc.summary
        this.talSummary = str.join(",")
        this.getLeftHeatmap(this.data.dataLeft.heatMap.heatmap_data_res_url)

        this.getRightHeatmap(this.data.dataRight.heatMap.heatmap_data_res_url)
        const result = this.data.mais
        // console.log(result, 'result')

        this.totalMai = getTotalMaiResult(result.summary_infos || [])
        // console.log(this.totalMai, 'this.totalMai')

        this.feature = { left: this.data.featureLeft, right: this.data.featureRight }

        // 分部
        this.branchResult = {
          left: getBranchResult(this.data.dataLeft, this.data.summaryDesc.left),
          right: getBranchResult(this.data.dataRight, this.data.summaryDesc.right)
        }

        // console.log(this.branchResult, 'this.branchResult')

        this.channelReault = getChannelResult(this.data.channels)
        this.heartrate = this.data.heartrate
        this.summary = this.data.summary
        this.symptomsResult = getSymptomsResult(result)
        this.qxjyResult = getQxjyResult(result)
        this.heresyResult = getHeresyResult(result)
        this.healthAdvice = {
          new_food_propose: result.new_food_propose.new_food_propose,
          other_propose: result.other_propose.other_propose,
          new_sport_propose: result.new_sport_propose.new_sport_propose
        }
      }else{
        this.$message({ message: '查询对象错误。', type: 'error' })
        return
      }
    })

  }
}
</script>

<style scoped>
@import url('./pulse.css');

/* img */
.img-hand {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('https://taiyi.oss-accelerate.aliyuncs.com/website/hybrid-app/report/mai_hand_right.jpg');
}

.img-hand.left {
  background-image: url('https://taiyi.oss-accelerate.aliyuncs.com/website/hybrid-app/report/mai_hand_left.jpg');
}

.img-heart-rate {
  background-image: url('https://taiyi.oss-accelerate.aliyuncs.com/website/hybrid-app/report/heart.png');
  background-repeat: no-repeat;
}

/* 样式定义 */
</style>
