<template>
  <div :class="['chart-branch-3d', { 'no-rotate': !rotate }]">
    <div ref="chart3d" :class="this.channelWidth ? 'chart3d' : 'chart3d1'" @click="canBuildHand"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-gl';
import { baseSeries } from './baseSeries.js'
// import surfaceImg from '@report/images/surface_img.jpg'
import { getLineOption } from '../three.js';

export default {
  props: {
    width: {
      type: Number,
      default: 300
    },
    a: {
      type: Number,
      default: 0.1
    },
    w: {
      type: Number,
      default: 0.1
    },
    mai: {
      type: String,
      default: null
    },
    rotate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chart: null,
      channelWidth: null
    }
  },
  methods: {
    canBuildHand() {
      // console.log(789)
      this.$emit('canbuildhand', true)
    },
    getWidth() {
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.resize()
        }
      })
      return this.width
    },
    getHeight() {
      return this.width * 0.8
    }
  },
  created() {
    const screenWidth = window.innerWidth;
    // console.log("当前屏幕宽度2:", screenWidth);
    this.channelWidth = screenWidth > 500 ? true : false
  },
  mounted() {
    if (!this.$refs.chart3d || !this.mai) return
    // console.log('mounted');

    this.canBuildHand()
    this.chart = echarts.init(this.$refs.chart3d)
    const key = /滑/.test(this.mai) ? 'hua' : /细/.test(this.mai) ? 'xi' : 'chang'
    const series = baseSeries[key]
    const surfaceImg = 'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/surfaceImg.jpg';
    const option = getLineOption(series, this.mai.split('、'), this.a, this.w, surfaceImg)
    this.chart.setOption(option)
    this.chart.on('finished', this.canBuildHand)
  }
}
</script>

<style scoped lang="scss">
.chart-branch-3d {
  text-align: center;
  position: relative;
  width: 50%;

  &>div {
    margin: auto;
  }

  &.no-rotate {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.chart3d {
  width: 339px;
  height: 271px;
}

.chart3d1 {
  width: 195px;
  height: 156px;
}

@media screen and (max-width: 390px) {
  .chart3d {
    width: 195px;
    height: 156px;
  }
}
</style>
