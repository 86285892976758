import axios from '../../axios'
const basePath = '/core/order'
export function save(data) {
  return axios({
    url: basePath + '/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/delete',
    method: 'post',
    data
  })
}
// 物流单号批量导入
export function importBindDeliverySnTemplate(data) {
  return axios({
    url: 'mgmt/order/importBindDeliverySnTemplate',
    method: 'post',
    data
  }, {
    headers: {
      'Content-Type': 'application/octet-stream'
    }
  })
}
// 下载物流单号批量导入模板
export const exportBindDeliverySnTemplate = (data) => {
  return axios({
    url: 'mgmt/order/exportBindDeliverySnTemplate',
    method: 'get',
    responseType: 'blob',
    params: data
  })
}
// 查看物流单导入记录
export const logFindPage = (data) => {
  return axios({
    url: 'mgmt/import/log/findPage',
    method: 'get',
    params: data
  })
}
// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + `/pageList?pageSize=${pageVo.pageSize}&curPage=${pageVo.curPage}`,
    method: 'post',
    data
  })
}

// 分页扫码支付查询
export const findScanOrderPage = (data, pageVo) => {
  return axios({
    url: `/mgmt/scan/code/order/getPage?pageSize=${pageVo.pageSize}&curPage=${pageVo.curPage}`,
    method: 'post',
    data
  })
}

// 分页会员卡查询
export const findCardOrderPage = (data, pageVo) => {
  return axios({
    url: `/mgmt/card/order/getPage?pageSize=${pageVo.pageSize}&curPage=${pageVo.curPage}`,
    method: 'post',
    data
  })
}

// 分页助理师订单列表查询
export const findAssistantOrderPage = (data, pageVo) => {
  return axios({
    url: `/mgmt/assistant/role/order/getPage?pageSize=${pageVo.pageSize}&curPage=${pageVo.curPage}`,
    method: 'post',
    data
  })
}

// 处方订单详情
export const findInfoByUniqueKey = data => {
  return axios({
    url: basePath + `/findInfoByUniqueKey`,
    method: 'get',
    params: data
  })
}
// 商品订单详情
export const goodsFindInfoByUniqueKey = data => {
  return axios({
    url: `/mgmt/store/order/detail`,
    method: 'get',
    params: data
  })
}
// 会员卡订单详情
export const cardOrderDetail = data => {
  return axios({
    url: `/mgmt/card/order/detail`,
    method: 'get',
    params: data
  })
}

export const findOrderWaitSendCount = data => {
  return axios({
    url: basePath + '/findOrderWaitSendCount',
    method: 'get',
    params: data
  })
}

export function operationOrder(data) {
  return axios({
    url: basePath + '/operationOrder',
    method: 'post',
    data
  })
}

// 查看物流
export const logisticsFindPage = data => {
  return axios({
    url: '/mgmt/online/logistics',
    method: 'get',
    params: data
  })
}

// 确认发货
export const confirmShipment = data => {
  return axios({
    url: '/mgmt/online/orders/confirmShipment',
    method: 'post',
    data
  })
}

// 线上确认签收
export function confirmReceipt(data) {
  return axios({
    url: '/mgmt/online/orders/confirmReceipt',
    method: 'post',
    data
  })
}
// 确认取货
export function pickUpCode(data) {
  return axios({
    url: '/mgmt/offline/orders/pickUp',
    method: 'post',
    data
  })
}
// 取消订单
export function orderCancel(data) {
  return axios({
    url: '/mgmt/order/_cancel',
    method: 'post',
    data
  })
}
// 订单退款
export function orderRefund(data) {
  return axios({
    url: '/mgmt/order/applyPartRefund',
    method: 'post',
    data
  })
}

// 处方详情 outpatientNo
export function treatmentRecordsDetails(data) {
  return axios({
    url: '/mgmt/treatmentRecords/details',
    method: 'get',
    params: data
  })
}

// 导出订单
export function orderExport(data) {
  return axios({
    url: '/mgmt/order/_export',
    responseType: 'blob',
    method: 'post',
    data
  })
}
// 导出订单明细
export function _exportItemDetail(data) {
  return axios({
    url: '/mgmt/order/_exportItemDetail',
    responseType: 'blob',
    method: 'post',
    data
  })
}

// 订单列表 处方订单分成
export function findOrderCommissionByOrderNo(orderNo) {
  return axios({
    url: '/core/order/findOrderCommissionByOrderNo?orderNo=' + orderNo,
    method: 'get'
  })
}

// 订单列表 会员卡订单分成
export function orderCommissionByOrderNo(orderNo) {
  return axios({
    url: '/mgmt/card/order/orderCommission?orderNo=' + orderNo,
    method: 'get'
  })
}

// 订单列表 商城订单分成
export function orderCommission(orderNo) {
  return axios({
    url: '/mgmt/store/order/orderCommission?orderNo=' + orderNo,
    method: 'get'
  })
}

// 订单详情 填写子物流订单号
export function deliverySubLogistics(data) {
  return axios({
    url: '/mgmt/online/orders/deliverySubLogistics',
    method: 'post',
    data
  })
}

// 订单详情 商品发货，只会对当前已绑定的商品发货
export function confirmShipmentV2(data) {
  return axios({
    url: '/mgmt/online/orders/confirmShipmentV2',
    method: 'post',
    data
  })
}

// 订单详情 会员礼包发货
export function confirmShipmentCard(data) {
  return axios({
    url: '/mgmt/card/order/confirmShipment',
    method: 'post',
    data
  })
}

// 订单详情 部分商品物流单号绑定类似子物流
export function deliverySubLogisticsV2(data) {
  return axios({
    url: '/mgmt/online/orders/deliverySubLogisticsV2',
    method: 'post',
    data
  })
}

// 订单详情 会员卡礼包发货
export function cardDeliverySubLogistics(data) {
  return axios({
    url: '/mgmt/card/order/deliverySubLogistics',
    method: 'post',
    data
  })
}

// 订单详情 获取当前商品的全部物流单号
export function orderGoodsLogistics(orderNo) {
  return axios({
    url: '/mgmt/online/logistics/getLogisticsList?orderNo=' + orderNo,
    method: 'get',
  })
}


// 订单详情 获取子物流信息
export function subLogistics(orderNo) {
  return axios({
    url: '/mgmt/order/getSubLogisticsList?orderNo=' + orderNo,
    method: 'get',
  })
}

// 订单详情 物流轨迹查询
export function subSearchWuliu(data) {
  return axios({
    url: '/mgmt/online/logistics/query',
    method: 'post',
    data
  })
}

// 订单详情 手动订阅物流
export function subLinkWuliu(data) {
  return axios({
    url: '/mgmt/online/logistics/subscribe',
    method: 'post',
    data
  })
}


// 订单运费详情
export function freightDetail(orderNo) {
  return axios({
    url: '/mgmt/order/getFreightDetail?orderNo=' + orderNo,
    method: 'get',
  })
}

// 订单列表 订单分账
export function getOderSharingAccountData(orderNo) {
  return axios({
    url: '/mgmt/order/sharingAccount/getOderSharingAccountData?orderNo=' + orderNo,
    method: 'get'
  })
}


