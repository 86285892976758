import { chartAngle } from './Branch/index.js';

// 扇形
/**
 * 绘制扇形
 * @param ctx canvas对象
 * @param w 宽
 * @param start 开始角度
 * @param end 结束的角度
 * @param bAndR 边框宽度、半径、颜色
 */
const setSector = (ctx, w, start, end, bAndR) => {
  const x = w / 2;
  const y = x;
  ctx.beginPath();
  ctx.strokeStyle = bAndR.bg;
  ctx.lineWidth = bAndR.b;
  ctx.arc(x, y, bAndR.r, start, end);
  ctx.stroke();
};

/**
 * 计算扇形区域并绘制
 * @param ctx canvas对象
 * @param width 宽度
 * @param sector 维度
 * @param level 等级
 * @param division 数、浮、沉一分为二
 */
export const chartChannel = (ctx, width, sector, level, division = false) => {
  // console.log(sector);
  if (['kong', 'shi'].includes(sector)) return;
  const r = chartAngle;

  let start = r[sector];
  let end = start + (2 / 8) * Math.PI;
  if (['fu', 'shu', 'chen'].includes(sector)) {
    if (division) {
      start = r[`${sector}2`];
      end = start + (2 / 8) * Math.PI;
      if (level === 0) {
        start = start - (1 / 8) * Math.PI;
      }
      end = end - (1 / 8) * Math.PI;
    } else {
      if (level !== 0) {
        end = end - (1 / 8) * Math.PI;
      }
    }
  }

  // 计算半径和边框宽度
  const bAndR = {
    b: 0,
    r: 0,
    bg: ''
  };

  const _bg = {
    3: 'rgba(219, 77, 80, 1)',
    2: 'rgba(255, 190, 51, 1)',
    1: 'rgba(254, 222, 111, 1)',
    0: 'rgba(81, 217, 194, 1)'
  };

  const _v = {
    3: {
      b: width * 0.0725,
      r: width * 0.3275
    },
    2: {
      b: width * 0.0725,
      r: width * 0.255
    },
    1: {
      b: width * 0.0725,
      r: width * 0.18375
    },
    0: {
      b: width * 0.1475,
      r: (width * 0.1475) / 2
    }
  };

  const _shu2v = {
    6: {
      b: width * 0.035,
      r: width * 0.346
    },
    5: {
      b: width * 0.035,
      r: width * 0.3095
    },
    4: {
      b: width * 0.035,
      r: width * 0.2723
    },
    3: {
      b: width * 0.035,
      r: width * 0.23625
    },
    2: {
      b: width * 0.035,
      r: width * 0.2
    },
    1: {
      b: width * 0.035,
      r: width * 0.16375
    },
    0: {
      b: width * 0.1475,
      r: (width * 0.1475) / 2
    }
  };

  if (sector === 'shu') {
    const _level = level;
    bAndR.b = _shu2v[_level].b;
    bAndR.r = _shu2v[_level].r;
    bAndR.bg = _bg[Math.ceil(_level / 2)];
  } else {
    const _level = level;
    bAndR.b = _v[_level].b;
    bAndR.r = _v[_level].r;
    bAndR.bg = _bg[_level];
  }

  setSector(ctx, width * 0.72875, start, end, bAndR);
};
